.form-group {
  margin-bottom: 15px;
}
.form-control {
  color: #565656;
}

.btn {
  font-size: 14px;
  border-radius: 2px;
  transition: all ease-in 0.1s;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
 }
.btn-round {
  border-radius: 5px;
}
.btn-custom {
  background-color: #71b6f9;
  border-color: #71b6f9;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom:active, .btn-custom.active, .btn-custom.focus, .open > .dropdown-toggle.btn-custom {
  background-color: #59a9f8 !important;
  border: 1px solid #59a9f8 !important;
}
.btn-bordered.btn-custom {
  background-color: #71b6f9;
  border-bottom: 2px solid #4fa4f8 !important;
  color: white !important;
}
.btn-success {
  background-color: #10c469 !important;
  border: 1px solid #10c469 !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus, .open > .dropdown-toggle.btn-success {
  background-color: #0eac5c !important;
  border: 1px solid #0eac5c !important;
}
.btn-bordered.btn-success {
  border-bottom: 2px solid #0da357 !important;
}
.btn-default {
  background-color: rgba(218, 230, 236, 0.3);
  border-color: rgba(218, 230, 236, 0.5);
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .btn-default.focus, .open > .dropdown-toggle.btn-default {
  background-color: rgba(218, 230, 236, 0.5) !important;
  border: 1px solid rgba(218, 230, 236, 0.7) !important;
}
.btn-bordered.btn-primary {
  border-bottom: 2px solid #0069d9 !important;
}
.btn-bordered.btn-danger {
  border-bottom: 2px solid #c82333 !important;
}
.btn-bordered.btn-secondary {
  border-bottom: 2px solid #5a6268 !important;
}
