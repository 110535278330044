@import "../../node_modules/breakpoint-sass/stylesheets/_breakpoint";

$black: #000;
$gray: #797979;
$white: #ffffff;

$blue: #36729c;
$green: #10c469;
$pink: #ff8acc;
$purple: #5b69bc;
$red: #ff5b5b;
$sky: #71b6f9;
$yellow: #f9c851;

$font-primary: 'Roboto', sans-serif;
$font-logo: 'Rancho', cursive;
$font-heading: 'Circe-Bold', sans-serif;
$font-subtitle: 'gothammedium';
$font-button: 'Open Sans', sans-serif;
$font-text: 'Circe-Regular';
$font-input: 'Open Sans', sans-serif;

$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$xl: (width 1680px 0);
$lg: (width 1199px 0);
$md: (width 991px 0);
$sm: (width 767px 0);
$xs: (width 576px 0);
$xxs: (width 500px 0);
