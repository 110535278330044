@import "src/scss/variables";

.light_theme {
  .app-header, .app-header-v2 {
    background: linear-gradient(to right, #3967c6, #4cb0e2) !important;

    .container {
      .search-form {
        .btn-search {
          span {
            background-color: #3966c6;
          }
        }
      }
    }
  }

  .general-info-section {
    .card-group {
      .card {
        .card-header {
          .icon {

            .bar {
              background: linear-gradient(to bottom, #3d6ec9, #a7c6ec) !important;
            }

            .circle-border {
              border: 1px solid #d1e2f9;

              .circle {
                background: #3966c6;
              }
            }
          }
        }
      }
    }
  }

  .rich-list-section {
    .table-rich-list {
      table {
        tbody {
          td.percent {
            .progress {
              .progress-bar {
                background-color: #3966c6;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    background: linear-gradient(to right, #3967c6, #4cb0e2) !important;
  }

}

.dark_theme {
  .app-header, .app-header-v2 {
    background: linear-gradient(to right, #29333c, #44505e) !important;

    .container {
      .search-form {
        .btn-search {
          span {
            background-color: #32373e;
          }
        }
      }
    }
  }

  .general-info-section {
    .card-group {
      .card {
        .card-header {
          .icon {

            .bar {
              background: linear-gradient(to bottom, #3b4047, #b1b6bd) !important;
            }

            .circle-border {
              border: 1px solid #cfe2f8;

              .circle {
                background: #32373e;
              }
            }
          }
        }
      }
    }
  }

  .rich-list-section {
    .table-rich-list {
      table {
        tbody {
          td.percent {
            .progress {
              .progress-bar {
                background-color: #32373e;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    background: linear-gradient(to right, #29333c, #475362) !important;
  }

}
