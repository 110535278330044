@import "variables";

body {
  background: $white;
  font-family: $font-primary;
  margin: 0;
  padding-bottom: 60px;
  overflow-x: hidden;
  color: $gray;
  font-size: 14px;
  line-height: 1.42857143;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
}

.align-items-center {
  align-items: center;
}

a:hover {
  text-decoration: none;
}

.justify-content-center {
  justify-content: center;
}

.d-flex {
  display: flex !important;
}

.d-flex-center {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-heading;
  margin: 10px 0;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

p {
  font-family: $font-text;
  line-height: 1.6;
  font-size: 18px;
}

span, input, button {
  font-family: $font-text;
}

.sub-title {
  text-align: center;
  padding-bottom: 40px;

  h3 {
    font-size: 32px;
    margin-top: 40px;
    color: #667c8f;
  }

  p {
    color: #7590a8;
    margin-top: 25px;
  }
}

table {
  border: 1px solid #cee1f8;
  margin-bottom: 0 !important;

  thead {
    th {
      font-size: 16px;
      font-family: $font-text;
      color: #3e5970;
      border-top: 1px solid #cee1f8 !important;
      border-bottom: 1px solid #cee1f8 !important;
      line-height: 2;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #f3f8ff !important;
    }

    td {
      font-family: $font-text;
      font-size: 16px;
      color: #3e5970;
      border-top: 1px solid #cee1f8 !important;
    }

    td.name {
      padding-left: 40px;
      font-weight: bold;
      color: #415c73;
    }
  }
}
