@import "src/scss/variables";

.address-detail-section {
  padding-top: 30px;

  .table-address {
    display: block;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;

    > div {
      .sub-title {
        padding-bottom: 10px;
      }
    }

    table {
      width: 100%;

      tbody {
        td.name {
          padding-left: 40px;
          font-weight: bold;
          color: #415c73;
          min-width: 170px;
        }

        td {
          word-break: break-all;

          > a {
            color: #4cb0e2;
          }
        }
      }
    }

    @include breakpoint($md) {
      flex-wrap: wrap;

      table {
        width: 100% !important;
      }
    }
  }
}

.transactions-section {
  padding-top: 30px;
  padding-bottom: 120px;

  .table-transactions {
    table {
      thead {
        @include breakpoint($md) {
          th.date {
            display: none;
          }
        }
        @include breakpoint($sm) {
          th.block {
            display: none;
          }
        }
        @include breakpoint($xs) {
          th.balance {
            display: none;
          }
        }
      }
      tbody {
        tr {
          line-height: 2;
        }
        td.minus {
          color: #c82852;
        }
        td.failed {
          text-decoration: line-through;
          opacity: 0.75;
        }
        td {
          > a {
            color: #4cb0e2;
          }
        }

        @include breakpoint($md) {
          td.date {
            display: none;
          }
        }
        @include breakpoint($sm) {
          td.block {
            display: none;
          }
        }
        @include breakpoint($xs) {
          td.balance {
            display: none;
          }
        }
      }
    }
  }
}
